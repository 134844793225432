window.dataLayer = window.dataLayer || [];

var googleTagManager = (function () {

  return {
    setForm: function (price, currencyCode, reservationNumber) {

      window.dataLayer.push({
        event: 'form_submitted',
        form: 'quote_request',
        value: price,
        reference_id: reservationNumber,
      });

    },
    setPage: function (p1) {

      window.dataLayer.push({
        event: 'virtual_pageview',
        path: p1,
        //path: window.location.href,
      });

    },
    setStartBookingWidget: function (){
      window.dataLayer.push({
        event: 'start_booking_widget'
      });
    },
    setPopupBookingWidget: function (errorMessage){
      window.dataLayer.push({
        event: 'reservation_widget_popup',
        message: errorMessage
      });
    },
    setSubmitBookingWidget: function (pickupLocation, droppoffLocation, pickupCountry, pickupDate, dropoffDate){
      window.dataLayer.push({
        event: 'start_reservation',
        pickup_location: pickupLocation,
        droppoff_location: droppoffLocation,
        pickup_country: pickupCountry,
        pickup_date: pickupDate,
        dropoff_date: dropoffDate,
      });
    },
    setChangePickup: function (pickupLocation, pickupCountry, pickupDate, dropoffDate){
      window.dataLayer.push({
        event: 'change',
        pickup_location: pickupLocation,
        pickup_country: pickupCountry,
        pickup_date: pickupDate,
        dropoff_date: dropoffDate,
      });
    },
    setCamperSelected: function (vehicleName, sipp, value, currency, supplier){
      window.dataLayer.push({
        event: 'select_car',
        vehicle_name: vehicleName,
        sipp: sipp,
        value: value,
        code: currency,
        supplier: supplier,
      });
    },
    setOptionSelected: function (optionType, totalPrice){
      window.dataLayer.push({
        event: 'select_option',
        option_type: optionType,
        total_price: totalPrice
      });
    },
    setCheckoutDetails: function (){
      window.dataLayer.push({
        event: 'checkout_details'
      });
    },
    setBookingOnRequest: function (transaction_id, booking_type, vehicleName, options_selected, reference_id, value, currency, tax, pickup_location, pickup_country, pickup_date, dropoff_date, dropoff_location, sipp, supplier){
      window.dataLayer.push({
        event: 'lead',
        Transaction_id: transaction_id,
        booking_type: booking_type,
        vehicle_name: vehicleName,
        options_selected: options_selected,
        reference_id: reference_id,
        value: value,
        code: currency,
        tax: tax,
        pickup_location: pickup_location,
        pickup_country: pickup_country,
        pickup_date: pickup_date,
        dropoff_date: dropoff_date,
        dropoff_location: dropoff_location,
        sipp: sipp,
        supplier: supplier,
      });
    },
    setBookingPayment: function (transaction_id, booking_type, vehicleName, options_selected, reference_id, value, currency, tax, pickup_location, pickup_country, pickup_date, dropoff_date, dropoff_location, sipp, supplier){
      window.dataLayer.push({
        event: 'purchase',
        Transaction_id: transaction_id,
        booking_type: booking_type,
        vehicle_name: vehicleName,
        options_selected: options_selected,
        reference_id: reference_id,
        value: value,
        code: currency,
        tax: tax,
        pickup_location: pickup_location,
        pickup_country: pickup_country,
        pickup_date: pickup_date,
        dropoff_date: dropoff_date,
        dropoff_location: dropoff_location,
        sipp: sipp,
        supplier: supplier,
      });
    },
    setBookingPaymentFailed: function (transaction_id, booking_type, vehicleName, options_selected, reference_id, value, currency, tax, pickup_location, pickup_country, pickup_date, dropoff_date, dropoff_location, sipp, supplier){
      window.dataLayer.push({
        event: 'cancelled',
        Transaction_id: transaction_id,
        booking_type: booking_type,
        vehicle_name: vehicleName,
        options_selected: options_selected,
        reference_id: reference_id,
        value: value,
        code: currency,
        tax: tax,
        pickup_location: pickup_location,
        pickup_country: pickup_country,
        pickup_date: pickup_date,
        dropoff_date: dropoff_date,
        dropoff_location: dropoff_location,
        sipp: sipp,
        supplier: supplier,
      });
    },




  }

})(googleTagManager || {})
